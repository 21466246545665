<template>
    <div class="check-in">
      <div class="container">
        <div class="row">
            <b-form class="col-12" @submit.prevent="submitForm">
              <div class="scan-camera" v-if="scanEnabled">
                <div class="scan-camera__header">
                  <p class="text-white position-absolute">Scan QR Code</p>
                  <img src="./trend-up.svg" alt="trend-up" class="trend-up-mobile">
                  <div class="upload-file">
                    <b-form-file
                      accept=".jpg, .png, .jpge"
                      @input="changeInputImage"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </div>
                </div>
                <qrcode-stream
                  v-if="scanEnabled"
                  :camera="camera"
                  @decode="onDecode"
                  @init="onInit"
                >
                  <img
                    src="./group.png"
                    alt=""
                    class="group-camera"
                  >
                </qrcode-stream>
              </div>
            </b-form>
        </div>
      </div>
      <div id="reader" class="d-none"></div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");
import { RepositoryFactory } from "@/apis/repositoryFactory";
const EventRepository = RepositoryFactory.get("event");
const AssetRepository = RepositoryFactory.get("asset");
import globalMixin from "@/core/mixins/global";
import { Html5Qrcode } from "html5-qrcode";
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  data() {
    return {
      result: "",
      camera: "auto",
      showScanConfirmation: false,
      isLoading: false,
      scanEnabled: true
    };
  },
  mixins: [validationMixin, globalMixin],
  validations: {
  },
  components: {
    QrcodeStream,
  },
  async mounted() {
    await this.$nextTick()
    document.getElementsByTagName("BODY")[0].style.paddingBottom = 0
  },
  methods: {
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;  
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    async changeInputImage (imageFile) {
      try {
        const html5QrCode = new Html5Qrcode(/* element id */ "reader");
        html5QrCode.scanFile(imageFile, /* showImage= */true)
        .then(qrCodeMessage => {
          try {
            const jsonData = JSON.parse(qrCodeMessage)
            if (!jsonData.token || !jsonData.address) {
              throw "invalid token"
            }
            this.$router.push({path: '/app/wallets/transfers',
              query: {
                wallettype: jsonData.token,
                address: jsonData.address,
                amount: jsonData.amount,
                message: jsonData.message,
                unit: jsonData.unit
              }
            })
            return
          } catch (err) {
            this.$alertMsg('fail', this.$t('swal.qr-fail'))
          }
        })
        .catch(err => {
          this.$alertMsg('fail', this.$t('swal.qr-fail'))
        });
      } catch (error) {
        console.log(error);
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },
    async onDecode(content) {
      if (!this.isValidHttpUrl(content)) {
        this.$alertMsg('fail', this.$t('swal.qr-fail'))
        return false;
      }
      window.location.href = content
      
      // try {
      //   const jsonData = JSON.parse(content)
      //   if (!jsonData.token || !jsonData.address) {
      //     throw "invalid token"
      //   }
      //   this.$router.push({path: '/app/wallets/transfers',
      //     query: {
      //       wallettype: jsonData.token,
      //       address: jsonData.address,
      //       amount: jsonData.amount,
      //       message: jsonData.message,
      //       unit: jsonData.unit
      //     }
      //   })
      //   return
      // } catch (err) {
      //   this.$alertMsg('fail', this.$t('swal.qr-fail'))
      // }
      this.pause()
      setTimeout(() => this.unpause(), 100)
    },
    unpause () {
      this.camera = 'auto'
    },
    pause () {
      this.camera = 'off'
    },

  },
};
</script>
<style lang="scss" scoped>
/deep/.iconsminds-left {
  display: none;
}
.top-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}
.check-in {
  background: url("./background.png") no-repeat center;
  background-size: cover;
  .card-body {
    background: #FFFFFF;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    margin: 0;
  }
  .btn-primary-submit {
    width: 100%;
    background: #007BFF;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    height: 40px;
    text-transform: capitalize;
  }
  .form-control {
    background: #F7F7F7!important;
    border-radius: 8px!important;
    border: none!important;
    height: 40px !important;
  }
  /deep/.invalid-feedback {
    box-shadow: none;
    color: #dc3545;
  }
  /deep/.input-group-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 0;
    background: transparent;
    border: none;
    z-index: 2;
  }
  .scan-camera {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 56px;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 10;
    .scan-camera__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 15px 16px;
      // border-bottom: 1px solid #43464E;
      p {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        z-index: 9;
        top: 30px;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .upload-file {
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 30px;
    width: 30px;
    z-index: 10;
  }
  .trend-up {
    opacity: 0;
  }
  .trend-up-mobile {
    position: absolute;
    right: 10px;
    top: 30px;
    width: 30px;
    z-index: 10;
  }
  .group-camera {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.check-in-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #141822;
}
.qrcode-stream-wrapper {
  width: 100%;
  height: 100vh;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.action {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .scanner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.btn-disabled {
  pointer-events: none;
  opacity: 0.6;
}

</style>
